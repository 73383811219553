<template>
  <b-tab title="TikTok">
    <b-card-actions
      ref="cardA"
      class="custom-card-actions border rounded"
      no-actions
      no-body
    >
      <template #header>
        <div class="card-header d-flex align-items-center justify-content-between">
          <b-card-title>TikTok Ads Account And Campaign List</b-card-title>
          <div class="btn-tiktok-login mt-2 mt-md-0">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="!!authCode"
              href="https://business-api.tiktok.com/portal/auth?app_id=7286275948041732097&state=tiktok&redirect_uri=https%3A%2F%2Fheroaix.heroleads.asia%2Fads-campaign-syncing"
            >
              {{ authCode ? 'Connected' : 'TikTok Login' }}
            </HeroButtonAction>
          </div>
        </div>
      </template>
      <div class="table-action d-flex justify-content-between px-2">
        <div class="table-action-filter d-flex">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search..."
            class="mb-0 mr-1"
            :style="'width: 80%'"
            @input="debounce(displayAdAccounts, 500)()"
          />
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="debounce(displayAdAccounts)()"
          />
        </div>
        <div class="btn-fetch-account mt-1 mt-sm-0">
          <HeroButtonAction
            v-if="$can('TikTok - Sync Campaigns', 'Ads Campaign Syncing')"
            type="button"
            variant="primary"
            @click="fetchAdAccounts"
          >
            Fetch Account List
          </HeroButtonAction>
        </div>
      </div>

      <b-table
        show-empty
        striped
        :responsive="true"
        :sticky-header="true"
        :items="items"
        :fields="tableConfig.fields"
        :per-page="0"
        :sort-by.sync="tableConfig.sortBy"
        :sort-direction.sync="tableConfig.sortDirection"
        :no-sort-reset="true"
        :no-local-sorting="true"
        class="mt-1"
        :style="`max-height: calc(100vh - 300px);`"
      >
        <template #cell(currency)="data">
          {{ data.item.currency || '-' }}
        </template>
        <template #cell(display)="data">
          <HeroButtonAction
            type="button"
            variant="primary"
            @click="onCampaignListModalShow('display', data.item)"
          >
            Display
          </HeroButtonAction>
        </template>
        <template #cell(fetch)="data">
          <HeroButtonAction
            type="button"
            variant="primary"
            @click="onCampaignListModalShow('fetch', data.item)"
          >
            Fetch
          </HeroButtonAction>
        </template>
      </b-table>

      <b-row class="px-2">
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="debounce(displayAdAccounts)()"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
      <CampaignListModal
        id="tiktok-campaign-list-modal"
        :campaign-list-modal-config="campaignListModalConfig"
        @onTokenExpired="authCode = ''"
      />
    </b-card-actions>
  </b-tab>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import DebounceMixin from '@/mixins/debounce'
import moment from 'moment'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import Toastification from '@/services/Toastification'
import store from '@/store'
import AxiosConfig from '@/services/AxiosConfigs'
import CampaignListModal from '@/views/ads-campaign-syncing/components/CampaignListModal.vue'
import axios from '@/libs/axios'
import { BCardTitle } from 'bootstrap-vue'

export default {
  components: {
    BCardTitle,
    CampaignListModal,
    HeroTablePagination,
    HeroTableStatus,
    HeroTablePerPage,
    HeroInputText,
    BCardActions,
    HeroButtonAction,
  },
  mixins: [DebounceMixin],
  props: {
    tabName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAccountLoaded: false,
      authCode: '',
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'analytic_account_name',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Account Id',
            key: 'analytic_account_id',
            sortable: false,
          },
          {
            label: 'Account Name',
            key: 'analytic_account_name',
            sortable: false,
          },
          {
            label: 'Currency',
            key: 'currency',
            sortable: false,
          },
          {
            label: 'Display Campaign List',
            key: 'display',
            sortable: false,
          },
          {
            ...this.$can('TikTok - Sync Campaigns', 'Ads Campaign Syncing') && {
              label: 'Fetch Campaign List',
              key: 'fetch',
              sortable: false,
            },
          },
        ],
      },
      campaignListModalConfig: {
        title: '',
        mode: 'display',
        tableConfig: {
          fields: [
            {
              label: 'Campaign Id',
              key: 'analytic_campaign_id',
              sortable: false,
              stickyColumn: true,
            },
            {
              label: 'Campaign Name',
              key: 'analytic_campaign_name',
              sortable: false,
            },
            {
              label: 'Daily Budget',
              key: 'daily_budget',
              stickyColumn: false,
            },
            {
              label: 'Lifetime Budget',
              key: 'lifetime_budget',
              stickyColumn: false,
            },
            {
              label: 'Status',
              key: 'status',
              stickyColumn: false,
            },
          ],
        },
        display: {
          url: 'ads-campaign-syncing/tiktok/display-campaigns',
          params: {},
        },
        fetch: {
          url: 'ads-campaign-syncing/tiktok/fetch-campaigns',
          params: {},
        },
      },
    }
  },
  computed: {
    userToken() {
      return store.getters['heroAiAuthentications/sanctumToken']
    },
  },
  watch: {
    tabName(newVal) {
      if (newVal === 'tiktok') {
        this.displayAdAccounts()
      }
    },
  },
  mounted() {
    if (this.tabName === 'tiktok') {
      this.tiktokLogin()
      this.displayAdAccounts()
    }
  },
  methods: {
    async tiktokLogin() {
      const { auth_code: authCode } = this.$route.query
      if (!authCode) {
        return
      }

      try {
        this.$refs.cardA.showLoading = true

        const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)

        const requestPayload = {
          auth_code: authCode,
        }

        await axios.post('ads-campaign-syncing/tiktok/store-access-token', requestPayload, axiosConfig)
        this.authCode = authCode
      } catch (error) {
        this.$toast(Toastification.getContentError(error.response.data.message))

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },

    async displayAdAccounts() {
      try {
        this.$refs.cardA.showLoading = true

        const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)
        const {
          filter, currentPage, perPage,
        } = this.tableConfig
        const params = {
          page: currentPage,
          perPage,
          q: filter,
        }

        const url = 'ads-campaign-syncing/tiktok/display-accounts'
        const { data } = await axios.get(url, { params, ...axiosConfig })
        this.tableConfig.currentPage = +(data.data.current_page || 1)
        this.tableConfig.perPage = +(data.data.per_page || 1)
        this.tableConfig.totalRows = +(data.data.total || 1)
        this.items = data.data.data

        if (!this.items.length && !this.isAccountLoaded) {
          this.$toast(Toastification.getContentError('Tiktok account data is empty. Please fetch accounts first.'))
        }

        this.isAccountLoaded = true
      } catch (error) {
        this.$toast(Toastification.getContentError(error.response.data.message))

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },

    async fetchAdAccounts() {
      if (!this.authCode) {
        this.$toast(Toastification.getContentError('Please login tiktok.'))
        return
      }

      try {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are fetching accounts.' })
        this.$refs.cardA.showLoading = true

        const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)

        const params = {
          auth_code: this.authCode,
        }

        const url = 'ads-campaign-syncing/tiktok/fetch-accounts'
        const { data } = await axios.get(url, { params, ...axiosConfig })
        this.$toast(Toastification.getContentSuccess(data.message))
        this.displayAdAccounts()
      } catch (error) {
        if (error.response.data.error) {
          this.$toast(Toastification.getContentError(error.response.data.error.message))
          if (error.response.data.code === 401) {
            this.authCode = ''
            return
          }
          return
        }

        this.$toast(Toastification.getContentError(error.response.data.message))

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        this.$swal().close()
        this.$refs.cardA.showLoading = false
      }
    },

    onCampaignListModalShow(mode, adAccount) {
      const { analytic_account_id: analyticAccountId, analytic_account_name: analyticAccountName, id } = adAccount

      if (mode === 'fetch') {
        if (!this.authCode) {
          this.$toast(Toastification.getContentError('Please login tiktok.'))
          return
        }

        this.campaignListModalConfig.fetch.params = {
          account_id: analyticAccountId,
          auth_code: this.authCode,
        }
      }

      this.campaignListModalConfig.display.params = {
        analytic_account_id: id,
      }

      this.campaignListModalConfig.title = `${analyticAccountName} [${analyticAccountId}]`
      this.campaignListModalConfig.mode = mode
      this.$bvModal.show('tiktok-campaign-list-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-card-actions {
  ::v-deep .card {
    margin-bottom: 0;
  }
}

.btn-tiktok-login {
  width: 100%;
  @media(min-width: 768px) {
    width: 200px;
  }
}

.table-action {
  flex-direction: column;
  @media(min-width: 576px) {
    flex-direction: row;
  }
  .table-action-filter {
    flex: 1 0 0%;
    @media(min-width: 1024px) {
      flex: 0.5 0 0%;
    }
  }
  .btn-fetch-account {
    @media(min-width: 576px) {
      width: 200px;
    }
    width: 100%;
  }
}

::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}
</style>
