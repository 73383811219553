<template>
  <b-card-actions
    ref="cardB"
    no-actions
    no-body
  >
    <b-card class="m-0">
      <b-row>
        <b-col cols="12">
          <!-- @input="doLoadTabData" -->
          <b-tabs
            v-model="tabIndex"
            pills
            @input="onTabInput"
          >
            <component
              :is="tab.component"
              v-for="tab in tabList"
              :key="tab.name"
              :tab-name="tabList[tabIndex].name"
              :tab-index="tabIndex"
            />
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </b-card-actions>
</template>
<script>
import Facebook from '@/views/ads-campaign-syncing/Facebook.vue'
import Google from '@/views/ads-campaign-syncing/Google.vue'
import TikTok from '@/views/ads-campaign-syncing/TikTok.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    Facebook,
    Google,
    TikTok,
    BCardActions,
  },
  data() {
    return {
      tabIndex: 0,
      tabList: [
        {
          ...this.$can('Facebook - Access', 'Ads Campaign Syncing') && {
            name: 'facebook',
            component: Facebook,
          },
        },
        {
          ...this.$can('Google - Access', 'Ads Campaign Syncing') && {
            name: 'google',
            component: Google,
          },
        },
        {
          ...this.$can('TikTok - Access', 'Ads Campaign Syncing') && {
            name: 'tiktok',
            component: TikTok,
          },
        },
      ],
    }
  },
  computed: {
    currentQueryTab() {
      const { tab: tabName } = this.$route.query
      return this.tabList.find(tab => tab.name === tabName)
    },
  },
  watch: {
    currentQueryTab(newVal) {
      this.tabIndex = newVal ? this.tabList.findIndex(tab => tab.name === newVal.name) : 0
    },
  },
  created() {
    this.tabList = this.tabList.filter(tab => Object.keys(tab).length > 0)

    if (this.$route.query.state === 'tiktok') {
      const tiktokTab = this.tabList.find(tab => tab.name === 'tiktok')
      if (tiktokTab) {
        this.tabIndex = this.tabList.findIndex(tab => tab.name === 'tiktok')
        this.$router.replace({ name: this.$route.name, query: { tab: tiktokTab.name } })
        return
      }
    }

    if (this.currentQueryTab) {
      this.tabIndex = this.tabList.findIndex(tab => tab.name === this.currentQueryTab.name)
    } else {
      this.$router.replace({ name: this.$route.name, query: { tab: this.tabList[0].name } })
    }
  },
  methods: {
    onTabInput(tabIndex) {
      const currentTab = this.tabList[tabIndex]

      if (this.currentQueryTab?.name !== currentTab.name) {
        this.$router.push({ name: this.$route.name, query: { tab: currentTab.name } })
      }
    },
  },
}
</script>
