<template>
  <b-tab title="Facebook">
    <b-card-actions
      v-if="canSwitchApp"
      ref="cardA"
      title="App Switching"
      class="custom-card-actions border rounded"
      no-actions
      no-body
    >
      <div class="app-switch d-flex align-items-end px-2 pb-2">
        <div class="app-switch-select">
          <HeroVueSelect
            id="app"
            v-model="appId"
            label="Apps"
            placeholder="Select app"
            class="mb-0"
            :clearable="false"
            :options="appList"
          />
        </div>
        <div class="btn-app-switch mt-1 mt-sm-0 ml-1">
          <HeroButtonAction
            type="button"
            variant="primary"
            @click="switchApplication"
          >
            Switch
          </HeroButtonAction>
        </div>
      </div>
    </b-card-actions>
    <b-card-actions
      ref="cardB"
      class="custom-card-actions border rounded mt-2"
      no-actions
      no-body
      show-actions
    >
      <template #header>
        <div class="card-header d-flex align-items-center justify-content-between">
          <b-card-title>Facebook Ads Account And Campaign List</b-card-title>
          <div class="btn-facebook-login mt-2 mt-md-0">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="!appId || auth.status === 'connected'"
              @click="facebookLogin"
            >
              <feather-icon icon="FacebookIcon" />
              {{ auth.status === 'connected' ? 'Connected' : 'Facebook Login' }}
            </HeroButtonAction>
          </div>
        </div>
      </template>
      <div class="table-action d-flex justify-content-between px-2">
        <div class="table-action-filter d-flex">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search..."
            class="mb-0 mr-1"
            :style="'width: 80%'"
            @input="debounce(displayAdAccounts, 500)()"
          />
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="debounce(displayAdAccounts)()"
          />
        </div>
        <div class="btn-fetch-account mt-1 mt-sm-0">
          <HeroButtonAction
            v-if="$can('Facebook - Sync Campaigns', 'Ads Campaign Syncing')"
            type="button"
            variant="primary"
            @click="fetchAdAccounts"
          >
            Fetch Account List
          </HeroButtonAction>
        </div>
      </div>

      <b-table
        show-empty
        striped
        :responsive="true"
        :sticky-header="true"
        :items="items"
        :fields="tableConfig.fields"
        :per-page="0"
        :sort-by.sync="tableConfig.sortBy"
        :sort-direction.sync="tableConfig.sortDirection"
        :no-sort-reset="true"
        :no-local-sorting="true"
        class="mt-1"
        :style="`max-height: calc(100vh - ${canSwitchApp ? '300' : '407'}px - 2rem);`"
      >
        <template #cell(display)="data">
          <HeroButtonAction
            type="button"
            variant="primary"
            @click="onCampaignListModalShow('display', data.item)"
          >
            Display
          </HeroButtonAction>
        </template>
        <template #cell(fetch)="data">
          <HeroButtonAction
            type="button"
            variant="primary"
            @click="onCampaignListModalShow('fetch', data.item)"
          >
            Fetch
          </HeroButtonAction>
        </template>
      </b-table>

      <b-row class="px-2">
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="debounce(displayAdAccounts)()"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
      <CampaignListModal
        id="facebook-campaign-list-modal"
        :campaign-list-modal-config="campaignListModalConfig"
        @onTokenExpired="auth = {}"
      />
    </b-card-actions>
  </b-tab>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import Toastification from '@/services/Toastification'
import store from '@/store'
import AxiosConfig from '@/services/AxiosConfigs'
import axios from '@/libs/axios'
import moment from 'moment/moment'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import CampaignListModal from '@/views/ads-campaign-syncing/components/CampaignListModal.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import DebounceMixin from '@/mixins/debounce'
import SweetAlert from '@/services/SweetAlert'
import { BCardTitle } from 'bootstrap-vue'

export default {
  components: {
    BCardTitle,
    HeroInputText,
    CampaignListModal,
    HeroTablePerPage,
    HeroTableStatus,
    HeroTablePagination,
    HeroButtonAction,
    HeroVueSelect,
    BCardActions,
  },
  mixins: [DebounceMixin],
  props: {
    tabName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAccountLoaded: false,
      sdkInitialized: false,
      auth: {},
      appId: '',
      appList: [],
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'analytic_account_name',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Account Id',
            key: 'analytic_account_id',
            sortable: false,
          },
          {
            label: 'Account Name',
            key: 'analytic_account_name',
            sortable: false,
          },
          {
            label: 'Display Campaign List',
            key: 'display',
            sortable: false,
          },
          {
            ...this.$can('Facebook - Sync Campaigns', 'Ads Campaign Syncing') && {
              label: 'Fetch Campaign List',
              key: 'fetch',
              sortable: false,
            },
          },
        ],
      },
      campaignListModalConfig: {
        title: '',
        mode: 'display',
        display: {
          url: 'ads-campaign-syncing/facebook/display-campaigns',
          params: {},
        },
        fetch: {
          url: 'ads-campaign-syncing/facebook/fetch-campaigns',
          params: {},
        },
      },
    }
  },
  computed: {
    userToken() {
      return store.getters['heroAiAuthentications/sanctumToken']
    },
    canSwitchApp() {
      return this.$can('Facebook - Switch Application', 'Ads Campaign Syncing')
    },
  },
  watch: {
    tabName(newVal) {
      if (newVal === 'facebook') {
        this.init()
      }
    },
  },
  mounted() {
    if (this.tabName === 'facebook') {
      this.init()
    }
  },
  methods: {
    async init() {
      this.displayAdAccounts()
      await this.getApplications()
      this.initFacebookSdk()
    },
    initFacebookSdk() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this
      const { api_version } = this.appList.find(app => app.app_id === this.appId)
      // eslint-disable-next-line func-names
      window.fbAsyncInit = function () {
        _this.sdkInitialized = true
        window.FB.init({
          appId: _this.appId,
          cookie: true,
          xfbml: true,
          version: api_version,
        })

        // ถ้าเชื่อมต่อ Facebook SDK ไม่ได้ คำสั่งด้านล่างจะแสดงข้อผิดพลาดบน Console
        window.FB.AppEvents.logPageView()
      }

      if (this.sdkInitialized || window.FB) {
        window.fbAsyncInit()
      }

      // eslint-disable-next-line func-names
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) { return }
        const js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    },

    facebookLogin() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this

      window.FB.login(async response => {
        try {
          if (response && !response.authResponse) {
            if (response?.error) {
              _this.$toast(Toastification.getContentError(response.error.message))
              return
            }
            return
          }

          this.items = []

          _this.auth = response

          const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)

          const { authResponse: { accessToken } } = this.auth

          const requestPayload = {
            app_id: this.appId,
            access_token: accessToken,
          }

          await axios.post('ads-campaign-syncing/facebook/store-access-token', requestPayload, axiosConfig)
          await _this.displayAdAccounts()
        } catch (error) {
          this.$toast(Toastification.getContentError(error.response.data.message))

          if (error.response.status === 401) {
            this.$router.push({ name: 'heroai-logout' })
          }
        }
      }, {
        scope: 'business_management, leads_retrieval, ads_management, pages_manage_ads, pages_manage_metadata, pages_read_engagement, pages_read_user_content',
      })
    },

    async getApplications() {
      try {
        if (this.canSwitchApp) {
          this.$refs.cardA.showLoading = true
        }

        const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)

        const url = 'ads-campaign-syncing/facebook/get-facebook-applications'
        const { data: { data: applications } } = await axios.get(url, axiosConfig)

        this.appList = applications.map(application => ({
          ...application,
          text: application.app_name,
          value: application.app_id,
        }))

        this.appId = applications.find(application => application.active).app_id
      } catch (error) {
        this.$toast(Toastification.getContentError(error.response.data.message))

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        if (this.canSwitchApp) {
          this.$refs.cardA.showLoading = false
        }
      }
    },

    async switchApplication() {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to switch app?' })

      if (alertResult.value) {
        try {
          this.$refs.cardA.showLoading = true

          const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)

          const url = 'ads-campaign-syncing/facebook/switching-application'
          const { data } = await axios.put(url, { app_id: this.appId }, axiosConfig)

          this.auth = {}
          this.initFacebookSdk()

          this.$toast(Toastification.getContentSuccess(data.message))
        } catch (error) {
          this.$toast(Toastification.getContentError(error.response.data.message))

          if (error.response.status === 401) {
            this.$router.push({ name: 'heroai-logout' })
          }
        } finally {
          this.$refs.cardA.showLoading = false
        }
      }
    },

    async displayAdAccounts() {
      try {
        this.$refs.cardB.showLoading = true

        const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)
        const {
          filter, currentPage, perPage,
        } = this.tableConfig
        const params = {
          page: currentPage,
          perPage,
          q: filter,
        }

        const url = 'ads-campaign-syncing/facebook/display-accounts'
        const { data } = await axios.get(url, { params, ...axiosConfig })
        this.tableConfig.currentPage = +(data.data.current_page || 1)
        this.tableConfig.perPage = +(data.data.per_page || 1)
        this.tableConfig.totalRows = +(data.data.total || 1)
        this.items = data.data.data

        if (!this.items.length && !this.isAccountLoaded) {
          this.$toast(Toastification.getContentError('Facebook account data is empty. Please fetch accounts first.'))
        }

        this.isAccountLoaded = true
      } catch (error) {
        this.$toast(Toastification.getContentError(error.response.data.message))

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        this.$refs.cardB.showLoading = false
      }
    },

    async fetchAdAccounts() {
      if (this.auth.status !== 'connected') {
        this.$toast(Toastification.getContentError('Please login facebook.'))
        return
      }

      try {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are fetching accounts.' })
        this.$refs.cardB.showLoading = true

        const axiosConfig = AxiosConfig.getJsonWithToken(this.userToken)
        const { authResponse: { accessToken } } = this.auth
        const params = {
          access_token: accessToken,
        }

        const url = 'ads-campaign-syncing/facebook/fetch-accounts'
        const { data } = await axios.get(url, { params, ...axiosConfig })
        this.$toast(Toastification.getContentSuccess(data.message))
        this.displayAdAccounts()
      } catch (error) {
        if (error.response.data.error) {
          this.$toast(Toastification.getContentError(error.response.data.error.message))

          if (error.response.data.code === 401) {
            this.auth = {}
            return
          }
          return
        }

        this.$toast(Toastification.getContentError(error.response.data.message))

        if (error.response.status === 401) {
          this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        this.$swal().close()
        this.$refs.cardB.showLoading = false
      }
    },

    onCampaignListModalShow(mode, adAccount) {
      const { analytic_account_id: analyticAccountId, analytic_account_name: analyticAccountName, id } = adAccount

      if (mode === 'fetch') {
        if (this.auth.status !== 'connected') {
          this.$toast(Toastification.getContentError('Please login facebook.'))
          return
        }

        const { authResponse: { accessToken } } = this.auth

        this.campaignListModalConfig.fetch.params = {
          account_id: analyticAccountId,
          access_token: accessToken,
        }
      }

      this.campaignListModalConfig.display.params = {
        analytic_account_id: id,
      }

      this.campaignListModalConfig.title = `${analyticAccountName} [${analyticAccountId}]`
      this.campaignListModalConfig.mode = mode
      this.$bvModal.show('facebook-campaign-list-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-card-actions {
  ::v-deep .card {
    margin-bottom: 0;
  }
}

.app-switch {
  flex-direction: column;
  @media(min-width: 576px) {
    flex-direction: row;
  }
  .app-switch-select {
    width: 100%;
    @media(min-width: 576px) {
      width: 350px;
    }
  }
  .btn-app-switch {
    width: 100%;
    @media(min-width: 576px) {
      width: 100px;
    }
  }
}

.btn-facebook-login {
  width: 100%;
  @media(min-width: 768px) {
    width: 200px;
  }
}

.table-action {
  flex-direction: column;
  @media(min-width: 576px) {
    flex-direction: row;
  }
  .table-action-filter {
    flex: 1 0 0%;
    @media(min-width: 1024px) {
      flex: 0.5 0 0%;
    }
  }
  .btn-fetch-account {
    @media(min-width: 576px) {
      width: 200px;
    }
    width: 100%;
  }
}

::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}
</style>
